// data.js
/*
Cambiar para que las imagenes se actualicen siempre 
?2.12'
*/
export const terrenosData = [
  {
    id: "1",
    titulo: "Local Comercial Peñalolen en Plena Avenida Principal",
    ubicacion: "Los Baqueanos, Peñalolen, Santiago, Chile",
    precio: 110000000,
    superficie: 130,
    tipo: "Comercial",
    servicios: [
      "Agua",
      "Luz",
      "Alcantarillado",
      "Internet",
      "Pavimentación",
      "Locomoción en puerta",
    ],
    descripcion:
      "Excelente terreno con local comercial en zona de alto tráfico. Perfecto para desarrollo comercial, cercano a locomoción. Espacio amplio techado, 2 Baños, Cocina, Bodega.",
    imagenes: [
      "/images/terrenos/1/img1.jpg?2.12",
      "/images/terrenos/1/img2.jpg?2.12",
      "/images/terrenos/1/img3.jpg?2.12",
    ],
    coordenadas: {
      lat: -33.47311163132901, // Ejemplo para Santiago
      lng: -70.53309947116392,
    },
    destacado: true, // 👈 Nueva propiedad
    fechaDestacado: "2024-11-06", // 👈 Para ordenar por más recientes
    datosContacto: {
      nombreContacto: "VentaTerreno.cl",
      telefono: "+56938995544",
      whatsapp: "+56938995544",
      correo: "contacto@ventaterreno.cl",
      horarioContacto: "Lunes a Viernes de 9:00 a 18:00 hrs",
      metodosContacto: ["telefono", "whatsapp", "correo"], // Para controlar qué botones mostrar
      notaAdicional: "Preferentemente contactar por WhatsApp", // Opcional
    },
  },
  {
    id: "2",
    titulo: "Terreno Residencial en Laguna Verde",
    ubicacion: "Laguna Verde, Valparaiso, Chile",
    precio: 20000000,
    superficie: 440,
    tipo: "Residencial",
    servicios: ["Factibilidad de Luz"],
    descripcion:
      "Hermoso terreno plano ideal para construir tu casa de ensueño. Ubicado en zona residencial de Laguna Verde, Comuna de Valparaiso.",
    imagenes: [
      "/images/terrenos/2/img1.jpg?2.12",
      "/images/terrenos/2/img2.jpg?2.12",
      "/images/terrenos/2/img3.jpg?2.12",
    ],
    coordenadas: {
      lat: -33.12016165969852, // Ejemplo para Santiago
      lng: -71.66652954510161,
    },
    destacado: true, // 👈 Nueva propiedad
    fechaDestacado: "2024-11-05", // 👈 Para ordenar por más recientes
    datosContacto: {
      nombreContacto: "VentaTerreno.cl",
      telefono: "+56938995544",
      whatsapp: "+56938995544",
      correo: "contacto@ventaterreno.cl",
      horarioContacto: "Lunes a Viernes de 9:00 a 18:00 hrs",
      metodosContacto: ["telefono", "whatsapp", "correo"], // Para controlar qué botones mostrar
      notaAdicional: "Preferentemente contactar por WhatsApp", // Opcional
    },
  },
  {
    id: "3",
    titulo: "Este podria ser tu aviso DESTACADO ",
    ubicacion: "Chile",
    precio: 300000000,
    superficie: 1000,
    tipo: "Residencial",
    servicios: [
      "Agua",
      "Luz",
      "Alcantarillado",
      "Internet",
      "Pavimentación",
      "Locomoción en puerta",
    ],
    descripcion: "Excelente oportunidad de publicar tu aviso. sin comision",
    imagenes: [
      "/images/terrenos/3/img1.jpg?2.12",
      "/images/terrenos/3/img2.jpg?2.12",
      "/images/terrenos/3/img3.jpg?2.12",
      "/images/terrenos/3/img4.jpg?2.12",
      "/images/terrenos/3/img5.jpg?2.12",
    ],
    coordenadas: {
      lat: -33.4489, // Ejemplo para Santiago
      lng: -70.6693,
    },
    destacado: true, // 👈 Nueva propiedad
    fechaDestacado: "2024-11-01", // 👈 Para ordenar por más recientes
    datosContacto: {
      nombreContacto: "Tu Nombre",
      telefono: "+569XXXXXXXX",
      whatsapp: "+569XXXXXXXX",
      correo: "tucorreo@tucorreo.com",
      horarioContacto: "Tu eliges tu horario de atencion",
      metodosContacto: ["telefono", "whatsapp", "correo"], // Para controlar qué botones mostrar
      notaAdicional: "Tu defines tu preferencia de contacto", // Opcional
    },
  },
  {
    id: "4",
    titulo: "Amplio Local Comercial en Peñalolen",
    ubicacion: "Los Baqueanos, Peñalolen, Santiago, Chile",
    precio: 110000000,
    superficie: 130,
    tipo: "Comercial",
    servicios: [
      "Agua",
      "Luz",
      "Alcantarillado",
      "Internet",
      "Pavimentación",
      "Locomoción en puerta",
    ],
    descripcion:
      "Excelente terreno con local comercial en zona de alto tráfico. Perfecto para desarrollo comercial, cercano a locomoción. Espacio amplio techado, 2 Baños, Cocina, Bodega.",
    imagenes: [
      "/images/terrenos/4/img1.jpg?2.12",
      "/images/terrenos/4/img2.jpg?2.12",
      "/images/terrenos/4/img3.jpg?2.12",
    ],
    coordenadas: {
      lat: -33.47311163132901, // Ejemplo para Santiago
      lng: -70.53309947116392,
    },
    destacado: true, // 👈 Nueva propiedad
    fechaDestacado: "2024-11-06", // 👈 Para ordenar por más recientes
    datosContacto: {
      nombreContacto: "VentaTerreno.cl",
      telefono: "+56938995544",
      whatsapp: "+56938995544",
      correo: "contacto@ventaterreno.cl",
      horarioContacto: "Lunes a Viernes de 9:00 a 18:00 hrs",
      metodosContacto: ["telefono", "whatsapp", "correo"], // Para controlar qué botones mostrar
      notaAdicional: "Preferentemente contactar por WhatsApp", // Opcional
    },
  },
  {
    id: "5",
    titulo: "Terreno Residencial en Laguna Verde",
    ubicacion: "Laguna Verde, Valparaiso, Chile",
    precio: 20000000,
    superficie: 440,
    tipo: "Residencial",
    servicios: ["Factibilidad de Luz"],
    descripcion:
      "Hermoso terreno plano ideal para construir tu casa de ensueño. Ubicado en zona residencial de Laguna Verde, Comuna de Valparaiso.",
    imagenes: [
      "/images/terrenos/5/img1.jpg?2.12",
      "/images/terrenos/5/img2.jpg?2.12",
      "/images/terrenos/5/img3.jpg?2.12",
      "/images/terrenos/5/img4.jpg?2.12",
      "/images/terrenos/5/img5.jpg?2.12",
    ],
    coordenadas: {
      lat: -33.12016165969852, // Ejemplo para Santiago
      lng: -71.66652954510161,
    },
    destacado: false, // 👈 Nueva propiedad
    fechaDestacado: "2024-11-05", // 👈 Para ordenar por más recientes
    datosContacto: {
      nombreContacto: "VentaTerreno.cl",
      telefono: "+56938995544",
      whatsapp: "+56938995544",
      correo: "contacto@ventaterreno.cl",
      horarioContacto: "Lunes a Viernes de 9:00 a 18:00 hrs",
      metodosContacto: ["telefono", "whatsapp", "correo"], // Para controlar qué botones mostrar
      notaAdicional: "Preferentemente contactar por WhatsApp", // Opcional
    },
  },
  {
    id: "6",
    titulo: "Este podria ser tu aviso, gratuito",
    ubicacion: "Chile",
    precio: 300000000,
    superficie: 1000,
    tipo: "Residencial",
    servicios: ["Agua", "Luz", "Drenaje", "Internet"],
    descripcion:
      "Excelente oportunidad de publicar tu aviso de forma gratuita y sin comision.",
    imagenes: [
      "/images/terrenos/6/img1.jpg?2.12",
      "/images/terrenos/6/img2.jpg?2.12",
      "/images/terrenos/6/img3.jpg?2.12",
      "/images/terrenos/6/img4.jpg?2.12",
      "/images/terrenos/6/img5.jpg?2.12",
    ],
    coordenadas: {
      lat: -33.4489, // Ejemplo para Santiago
      lng: -70.6693,
    },
    destacado: false, // 👈 Nueva propiedad
    fechaDestacado: "2000-01-01", // 👈 Para ordenar por más recientes
    datosContacto: {
      nombreContacto: "Tu Nombre",
      telefono: "+569XXXXXXXX",
      whatsapp: "+569XXXXXXXX",
      correo: "tucorreo@tucorreo.com",
      horarioContacto: "Tu eliges tu horario de atencion",
      metodosContacto: ["telefono", "whatsapp", "correo"], // Para controlar qué botones mostrar
      notaAdicional: "Tu defines tu preferencia de contacto", // Opcional
    },
  },
  {
    id: "7",
    titulo: "Local Comercial Peñalolen en Plena Avenida Principal",
    ubicacion: "Los Baqueanos, Peñalolen, Santiago, Chile",
    precio: 110000000,
    superficie: 130,
    tipo: "Comercial",
    servicios: [
      "Agua",
      "Luz",
      "Alcantarillado",
      "Internet",
      "Pavimentación",
      "Locomoción en puerta",
    ],
    descripcion:
      "Excelente terreno con local comercial en zona de alto tráfico. Perfecto para desarrollo comercial, cercano a locomoción. Espacio amplio techado, 2 Baños, Cocina, Bodega.",
    imagenes: [
      "/images/terrenos/7/img1.jpg?2.12",
      "/images/terrenos/7/img2.jpg?2.12",
      "/images/terrenos/7/img3.jpg?2.12",
    ],
    coordenadas: {
      lat: -33.47311163132901, // Ejemplo para Santiago
      lng: -70.53309947116392,
    },
    destacado: false, // 👈 Nueva propiedad
    fechaDestacado: "2024-11-06", // 👈 Para ordenar por más recientes
    datosContacto: {
      nombreContacto: "VentaTerreno.cl",
      telefono: "+56938995544",
      whatsapp: "+56938995544",
      correo: "contacto@ventaterreno.cl",
      horarioContacto: "Lunes a Viernes de 9:00 a 18:00 hrs",
      metodosContacto: ["telefono", "whatsapp", "correo"], // Para controlar qué botones mostrar
      notaAdicional: "Preferentemente contactar por WhatsApp", // Opcional
    },
  },
  {
    id: "8",
    titulo: "Terreno Residencial en Laguna Verde",
    ubicacion: "Laguna Verde, Valparaiso, Chile",
    precio: 20000000,
    superficie: 440,
    tipo: "Residencial",
    servicios: ["Factibilidad de Luz"],
    descripcion:
      "Hermoso terreno plano ideal para construir tu casa de ensueño. Ubicado en zona residencial de Laguna Verde, Comuna de Valparaiso.",
    imagenes: [
      "/images/terrenos/8/img1.jpg?2.12",
      "/images/terrenos/8/img2.jpg?2.12",
    ],
    coordenadas: {
      lat: -33.12016165969852, // Ejemplo para Santiago
      lng: -71.66652954510161,
    },
    destacado: false, // 👈 Nueva propiedad
    fechaDestacado: "2024-11-05", // 👈 Para ordenar por más recientes
    datosContacto: {
      nombreContacto: "VentaTerreno.cl",
      telefono: "+56938995544",
      whatsapp: "+56938995544",
      correo: "contacto@ventaterreno.cl",
      horarioContacto: "Lunes a Viernes de 9:00 a 18:00 hrs",
      metodosContacto: ["telefono", "whatsapp", "correo"], // Para controlar qué botones mostrar
      notaAdicional: "Preferentemente contactar por WhatsApp", // Opcional
    },
  },
  {
    id: "9",
    titulo: "Esta podria ser la publicacion de tu propiedad",
    ubicacion: "Chile",
    precio: 300000000,
    superficie: 1000,
    tipo: "Residencial",
    servicios: ["Agua", "Luz", "Drenaje", "Internet"],
    descripcion:
      "Excelente oportunidad de publicar tu aviso de forma gratuita y sin comision.",
    imagenes: [
      "/images/terrenos/9/img1.jpg?2.12",
      "/images/terrenos/9/img2.jpg?2.12",
      "/images/terrenos/9/img3.jpg?2.12",
      "/images/terrenos/9/img4.jpg?2.12",
      "/images/terrenos/9/img5.jpg?2.12",
    ],
    coordenadas: {
      lat: -33.4489, // Ejemplo para Santiago
      lng: -70.6693,
    },
    destacado: false, // 👈 Nueva propiedad
    fechaDestacado: "2000-01-01", // 👈 Para ordenar por más recientes
    datosContacto: {
      nombreContacto: "Tu Nombre",
      telefono: "+569XXXXXXXX",
      whatsapp: "+569XXXXXXXX",
      correo: "tucorreo@tucorreo.com",
      horarioContacto: "Tu eliges tu horario de atencion",
      metodosContacto: ["telefono", "whatsapp", "correo"], // Para controlar qué botones mostrar
      notaAdicional: "Tu defines tu preferencia de contacto", // Opcional
    },
  },
];
