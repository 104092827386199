// src/components/layout/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg font-bold mb-4">VentaTerreno.cl</h3>
            <p className="text-gray-300">
              Tu mejor opción para encontrar el terreno ideal
            </p>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Enlaces rápidos</h3>
            <ul className="space-y-2">
              <li><a href="/terrenos" className="text-gray-300 hover:text-white">Terrenos</a></li>
              <li><a href="/contacto" className="text-gray-300 hover:text-white">Contacto</a></li>
              <li><a href="/nosotros" className="text-gray-300 hover:text-white">Nosotros</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Contacto</h3>
            <ul className="space-y-2 text-gray-300">
              <li>Email: contacto@ventaterreno.cl</li>
              <li>Teléfono: (+569) 3899 5544</li>
              <li>WhatsApp: (+569) 3899 5544</li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700">
          <p className="text-center text-gray-300">
            2024 VentaTerreno.cl
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;