// src/components/terrenos/TerrenoCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Star } from 'lucide-react';

const TerrenoCard = ({ terreno }) => {
  return (
    <Link
      to={`/terreno/${terreno.id}`}
      className={`
        block bg-white rounded-lg shadow-md overflow-hidden
        hover:shadow-lg transition-all duration-300
        h-[440px] // Altura fija para todas las tarjetas
        ${terreno.destacado ? 'ring-2 ring-yellow-400' : ''}
      `}
    >
      {/* Contenedor de imagen con altura fija */}
      <div className="relative w-full h-48">
        <img
          src={terreno.imagenes[0]}
          alt={terreno.titulo}
          className="w-full h-full object-cover"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/images/placeholder.jpg';
          }}
        />
        
        {terreno.destacado && (
          <div className="absolute top-2 right-2 z-10 flex items-center space-x-1 bg-yellow-400 text-yellow-900 px-3 py-1 rounded-full shadow-lg">
            <Star size={16} fill="currentColor" />
            <span className="text-sm font-semibold">Destacado</span>
          </div>
        )}

        {/* Indicador de cantidad de imágenes */}
        {terreno.imagenes.length > 1 && (
          <div className="absolute bottom-2 right-2 bg-black/70 text-white px-2 py-1 rounded text-xs">
            {terreno.imagenes.length} fotos
          </div>
        )}
      </div>

      {/* Contenedor del contenido con altura fija */}
      <div className="p-4 flex flex-col h-[calc(440px-192px)]"> {/* Resto de altura descontando la imagen */}
        {/* Título con dos líneas máximo */}
        <h3 className="text-lg font-semibold mb-2 line-clamp-2 h-14">
          {terreno.titulo}
        </h3>

        {/* Ubicación con una línea máximo */}
        <p className="text-gray-600 mb-2 text-sm line-clamp-1">
          {terreno.ubicacion}
        </p>

        {/* Precio */}
        <p className="text-gray-800 font-bold text-xl mb-2">
          ${terreno.precio.toLocaleString()}
        </p>

        {/* Detalles */}
        <div className="flex items-center space-x-4 text-sm text-gray-500 mb-3">
          <span>{terreno.superficie} m²</span>
          <span>{terreno.tipo}</span>
        </div>

        {/* Servicios - siempre al fondo de la tarjeta */}
        <div className="mt-auto">
          <div className="flex flex-wrap gap-2">
            {terreno.servicios.slice(0, 3).map((servicio, index) => (
              <span
                key={index}
                className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full"
              >
                {servicio}
              </span>
            ))}
            {terreno.servicios.length > 3 && (
              <span className="px-2 py-1 bg-gray-100 text-gray-800 text-xs rounded-full">
                +{terreno.servicios.length - 3} más
              </span>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TerrenoCard;
