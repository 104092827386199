// src/components/shared/DestacadosCarousel.js
import React, { useRef, useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import TerrenoCard from "../terrenos/TerrenoCard";

const DestacadosCarousel = ({ terrenos }) => {
  const scrollRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const checkScroll = () => {
    if (scrollRef.current) {
      setCanScrollLeft(currentIndex > 0);
      setCanScrollRight(currentIndex < terrenos.length - 1);
    }
  };

  useEffect(() => {
    checkScroll();
  }, [currentIndex, terrenos.length]);

  const scrollToIndex = (index) => {
    const container = scrollRef.current;
    if (!container) return;

    const cardElements = container.children;
    if (cardElements.length === 0) return;

    const cardWidth = cardElements[0].offsetWidth;
    const gap = 24; // Este valor debe coincidir con el gap en el className (gap-6 = 24px)

    container.scrollTo({
      left: index * (cardWidth + gap),
      behavior: "smooth",
    });
  };

  const scroll = (direction) => {
    const newIndex =
      direction === "left"
        ? Math.max(0, currentIndex - 1)
        : Math.min(terrenos.length - 1, currentIndex + 1);

    setCurrentIndex(newIndex);
    scrollToIndex(newIndex);
  };

  // Actualizar el índice cuando se hace scroll manualmente
  const handleScroll = () => {
    const container = scrollRef.current;
    if (!container) return;

    const cardWidth = container.children[0]?.offsetWidth || 0;
    const gap = 24;
    const newIndex = Math.round(container.scrollLeft / (cardWidth + gap));

    if (newIndex !== currentIndex) {
      setCurrentIndex(newIndex);
    }
  };

  return (
    <div className="relative py-2">
      {" "}
      {/* Agregado py-2 para dar espacio arriba y abajo */}
      {/* Botón Izquierdo */}
      <div className="absolute -left-6 top-1/2 -translate-y-1/2 z-30">
        <button
          onClick={() => scroll("left")}
          className={`
            bg-white hover:bg-gray-100 
            shadow-lg hover:shadow-xl 
            rounded-full p-3
            transform transition-all duration-200
            ${
              canScrollLeft
                ? "opacity-100 translate-x-0"
                : "opacity-0 -translate-x-4 pointer-events-none"
            }
          `}
          disabled={!canScrollLeft}
          aria-label="Anterior"
        >
          <ChevronLeft size={24} className="text-gray-800 drop-shadow-sm" />
        </button>
      </div>
      {/* Contenedor del Carrusel con padding ajustado */}
      <div
        ref={scrollRef}
        className="flex overflow-x-auto scrollbar-hide scroll-smooth gap-6 px-1 py-2" // Agregado py-2
        onScroll={handleScroll}
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          WebkitOverflowScrolling: "touch",
        }}
      >
        {terrenos.map((terreno) => (
          <div
            key={terreno.id}
            className="flex-none w-full sm:w-[calc(50%-12px)] md:w-[calc(33.333%-16px)] lg:w-[calc(33.333%-16px)]"
          >
            <TerrenoCard terreno={terreno} />
          </div>
        ))}
      </div>
      {/* Botón Derecho */}
      <div className="absolute -right-6 top-1/2 -translate-y-1/2 z-30">
        <button
          onClick={() => scroll("right")}
          className={`
            bg-white hover:bg-gray-100 
            shadow-lg hover:shadow-xl 
            rounded-full p-3
            transform transition-all duration-200
            ${
              canScrollRight
                ? "opacity-100 translate-x-0"
                : "opacity-0 translate-x-4 pointer-events-none"
            }
          `}
          disabled={!canScrollRight}
          aria-label="Siguiente"
        >
          <ChevronRight size={24} className="text-gray-800 drop-shadow-sm" />
        </button>
      </div>
      {/* Gradientes a los lados */}
      {canScrollLeft && (
        <div className="absolute left-0 top-2 bottom-2 w-20 bg-gradient-to-r from-white/50 to-transparent z-20 pointer-events-none" />
      )}
      {canScrollRight && (
        <div className="absolute right-0 top-2 bottom-2 w-20 bg-gradient-to-l from-white/50 to-transparent z-20 pointer-events-none" />
      )}
      {/* Indicadores de posición ajustados */}
      <div className="absolute -bottom-4 left-0 right-0 flex justify-center gap-2">
        {terrenos.map((_, index) => (
          <button
            key={index}
            onClick={() => {
              setCurrentIndex(index);
              scrollToIndex(index);
            }}
            className={`
              w-2 h-2 rounded-full transition-all duration-200
              ${
                index === currentIndex
                  ? "bg-blue-600 w-4"
                  : "bg-gray-300 hover:bg-gray-400"
              }
            `}
            aria-label={`Ir a terreno ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default DestacadosCarousel;
