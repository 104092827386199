import React from 'react';

const UserTypeDisplay = ({ userType }) => {
  const getUserTypeInfo = (type) => {
    switch (type) {
      case 'admin':
        return {
          label: 'Administrador',
          className: 'text-xs font-medium text-green-600 bg-green-100 px-2 py-1 rounded-full'
        };
      case 'user1':
        return {
          label: 'Usuario',
          className: 'text-xs font-medium text-blue-600 bg-blue-100 px-2 py-1 rounded-full'
        };
      case 'user2':
        return {
          label: 'Premium',
          className: 'text-xs font-medium text-red-600 bg-red-100 px-2 py-1 rounded-full'
        };
      default:
        return {
          label: 'Usuario',
          className: 'text-xs font-medium text-gray-600 bg-gray-100 px-2 py-1 rounded-full'
        };
    }
  };

  const { label, className } = getUserTypeInfo(userType);

  return <div className={className}>{label}</div>;
};

export default UserTypeDisplay;