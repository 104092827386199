
const users = [
  {
    id: 1,
    email: 'koteleafer@gmail.com',
    password: 'Polita123*',
    nombre: 'Kote',
    telefono: '',
    tipo: 'admin'
  },
  {
    id: 2,
    email: 'chilenorasb@gmail.cl',
    password: 'Valky123*',
    nombre: 'Tito',
    telefono: '',
    tipo: 'admin'
  },
  {
    id: 3,
    email: 'gunter@ventaterreno.cl',
    password: 'Gunter123*',
    nombre: 'Gunter',
    telefono: '+56999999999',
    tipo: 'user1'
  },
  {
    id: 4,
    email: 'rocky@ventaterreno.cl',
    password: 'Rocky123*',
    nombre: 'Rocky',
    telefono: '+56999999999',
    tipo: 'user2'
  }
];

export default users;