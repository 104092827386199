// src/components/shared/MapLink.js
import React from 'react';
import { MapPin } from 'lucide-react';

const MapLink = ({ coordenadas, direccion }) => {
  const getMapUrl = () => {
    if (coordenadas && coordenadas.lat && coordenadas.lng) {
      return `https://www.openstreetmap.org/?mlat=${coordenadas.lat}&mlon=${coordenadas.lng}#map=16/${coordenadas.lat}/${coordenadas.lng}`;
    }
    // Si no hay coordenadas, usa la dirección
    return `https://www.openstreetmap.org/search?query=${encodeURIComponent(direccion)}`;
  };

  return (
    <a
      href={getMapUrl()}
      target="_blank"
      rel="noopener noreferrer"
      className="inline-flex items-center text-blue-600 hover:text-blue-800"
    >
      <MapPin size={20} className="mr-2" />
      <span>Ver en mapa completo</span>
    </a>
  );
};

export default MapLink;