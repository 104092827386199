// src/components/shared/ContactModal.js
import React from 'react';
import { X, Phone, Mail, Clock, MessageCircle } from 'lucide-react';

const ContactModal = ({ isOpen, onClose, datosContacto }) => {
  if (!isOpen) return null;

  const handleWhatsAppClick = (numero) => {
    window.open(`https://wa.me/${numero.replace(/[^0-9]/g, '')}`, '_blank');
  };

  return (
    <div 
      className="fixed inset-0 bg-black/70 z-[9999] flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-lg w-full max-w-md relative"
        onClick={e => e.stopPropagation()}
      >
        {/* Botón cerrar */}
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
          aria-label="Cerrar"
        >
          <X size={24} />
        </button>

        {/* Encabezado */}
        <div className="p-6 border-b">
          <h2 className="text-2xl font-bold text-gray-800">Datos de Contacto</h2>
          {datosContacto.nombreContacto && (
            <p className="mt-2 text-gray-600">{datosContacto.nombreContacto}</p>
          )}
        </div>

        {/* Cuerpo */}
        <div className="p-6 space-y-6">
          {/* Botones de contacto */}
          <div className="grid gap-4">
            {datosContacto.metodosContacto.includes('whatsapp') && datosContacto.whatsapp && (
              <div className="space-y-1">
                <button
                  onClick={() => handleWhatsAppClick(datosContacto.whatsapp)}
                  className="w-full flex items-center justify-center gap-2 bg-green-500 text-white py-3 px-4 rounded-lg hover:bg-green-600 transition-colors"
                >
                  <MessageCircle size={20} />
                  <span>WhatsApp: {datosContacto.whatsapp}</span>
                </button>
                <p className="text-xs text-gray-500 text-center">Click para abrir chat de WhatsApp</p>
              </div>
            )}

            {datosContacto.metodosContacto.includes('telefono') && datosContacto.telefono && (
              <div className="space-y-1">
                <div className="flex flex-col items-stretch">
                  <a
                    href={`tel:${datosContacto.telefono}`}
                    className="flex items-center justify-center gap-2 bg-blue-500 text-white py-3 px-4 rounded-t-lg hover:bg-blue-600 transition-colors"
                  >
                    <Phone size={20} />
                    <span>Llamar al {datosContacto.telefono}</span>
                  </a>
                  <div className="bg-blue-100 text-blue-800 py-2 px-4 rounded-b-lg text-center text-sm">
                    {datosContacto.telefono}
                  </div>
                </div>
                <p className="text-xs text-gray-500 text-center">Click para llamar o copia el número</p>
              </div>
            )}

            {datosContacto.metodosContacto.includes('correo') && datosContacto.correo && (
              <div className="space-y-1">
                <div className="flex flex-col items-stretch">
                  <a
                    href={`mailto:${datosContacto.correo}`}
                    className="flex items-center justify-center gap-2 bg-gray-500 text-white py-3 px-4 rounded-t-lg hover:bg-gray-600 transition-colors"
                  >
                    <Mail size={20} />
                    <span>Enviar correo</span>
                  </a>
                  <div className="bg-gray-100 text-gray-800 py-2 px-4 rounded-b-lg text-center text-sm break-all">
                    {datosContacto.correo}
                  </div>
                </div>
                <p className="text-xs text-gray-500 text-center">Click para abrir tu cliente de correo o copia la dirección</p>
              </div>
            )}
          </div>

          {/* Horario de contacto */}
          {datosContacto.horarioContacto && (
            <div className="flex items-start gap-2 bg-gray-50 p-4 rounded-lg mt-6">
              <Clock size={20} className="text-gray-500 flex-shrink-0 mt-1" />
              <div>
                <p className="font-medium text-gray-700">Horario de contacto:</p>
                <p className="text-gray-600">{datosContacto.horarioContacto}</p>
              </div>
            </div>
          )}

          {/* Nota adicional */}
          {datosContacto.notaAdicional && (
            <div className="text-sm text-gray-600 bg-yellow-50 p-4 rounded-lg">
              {datosContacto.notaAdicional}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactModal;