// src/components/shared/Filtros.js
import React, { useState, useEffect } from 'react';
import { X, ChevronDown, ChevronUp } from 'lucide-react';

const Filtros = ({ filtros, setFiltros, resultCount, totalCount }) => { // Recibimos los contadores como props
  const [isExpanded, setIsExpanded] = useState(false);

  // Verificar si hay algún filtro activo
  const hayFiltrosActivos = Object.values(filtros).some(valor => 
    valor !== '' && valor !== null && valor !== undefined
  );

  // Guardar estado de expansión en localStorage
  useEffect(() => {
    const savedExpanded = localStorage.getItem('filtrosExpanded');
    if (savedExpanded !== null) {
      setIsExpanded(JSON.parse(savedExpanded));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('filtrosExpanded', JSON.stringify(isExpanded));
  }, [isExpanded]);

  // Guardar filtros en localStorage
  useEffect(() => {
    localStorage.setItem('filtrosActivos', JSON.stringify(filtros));
  }, [filtros]);

  const limpiarTodosFiltros = () => {
    setFiltros({
      precioMin: '',
      precioMax: '',
      ubicacion: '',
      tipo: '',
      superficieMin: '',
      superficieMax: '',
      servicios: [],
      estado: ''
    });
  };

  const limpiarFiltro = (nombreFiltro) => {
    setFiltros({
      ...filtros,
      [nombreFiltro]: nombreFiltro === 'servicios' ? [] : ''
    });
  };

  return (
    <div className="bg-white rounded-lg shadow transition-all duration-300">
      {/* Cabecera de filtros siempre visible */}
      <div className="p-4 border-b">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <h2 className="text-xl font-bold">Filtros</h2>
            {hayFiltrosActivos && (
              <button
                onClick={limpiarTodosFiltros}
                className="flex items-center text-gray-500 hover:text-gray-700 transition-colors"
                title="Limpiar todos los filtros"
              >
                <X size={20} className="mr-1" />
                <span className="text-sm">Limpiar filtros</span>
              </button>
            )}
            {resultCount > 0 && (
              <span className="text-sm text-gray-500">
                Mostrando {resultCount} de {totalCount} propiedades
              </span>
            )}
          </div>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            title={isExpanded ? "Ocultar filtros" : "Mostrar filtros"}
          >
            {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </button>
        </div>

        {/* Resumen de filtros activos (siempre visible) */}
        {hayFiltrosActivos && (
          <div className="mt-4 flex flex-wrap gap-2">
            {Object.entries(filtros).map(([key, value]) => {
              if (!value || (Array.isArray(value) && value.length === 0)) return null;
              
              let label = '';
              switch(key) {
                case 'precioMin':
                  label = `Desde $${parseInt(value).toLocaleString()}`;
                  break;
                case 'precioMax':
                  label = `Hasta $${parseInt(value).toLocaleString()}`;
                  break;
                case 'superficieMin':
                  label = `Desde ${value}m²`;
                  break;
                case 'superficieMax':
                  label = `Hasta ${value}m²`;
                  break;
                case 'servicios':
                  if (Array.isArray(value) && value.length > 0) {
                    label = `Servicios: ${value.join(', ')}`;
                  }
                  break;
                default:
                  label = value;
              }

              return label ? (
                <span key={key} className="inline-flex items-center bg-blue-100 text-blue-800 text-xs px-3 py-1 rounded-full">
                  {label}
                  <button
                    onClick={() => limpiarFiltro(key)}
                    className="ml-2 hover:text-blue-900"
                  >
                    <X size={14} />
                  </button>
                </span>
              ) : null;
            })}
          </div>
        )}
      </div>

      {/* Contenido de filtros colapsable */}
      <div className={`overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="p-4 space-y-6">
          {/* Precio */}
          <div className="space-y-2">
            <h3 className="font-semibold">Precio</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="relative">
                <input
                  type="number"
                  placeholder="Mínimo"
                  className="w-full p-2 pr-8 border rounded"
                  value={filtros.precioMin}
                  onChange={(e) => setFiltros({ ...filtros, precioMin: e.target.value })}
                />
                {filtros.precioMin && (
                  <button
                    onClick={() => limpiarFiltro('precioMin')}
                    className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    <X size={16} />
                  </button>
                )}
              </div>
              <div className="relative">
                <input
                  type="number"
                  placeholder="Máximo"
                  className="w-full p-2 pr-8 border rounded"
                  value={filtros.precioMax}
                  onChange={(e) => setFiltros({ ...filtros, precioMax: e.target.value })}
                />
                {filtros.precioMax && (
                  <button
                    onClick={() => limpiarFiltro('precioMax')}
                    className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    <X size={16} />
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Superficie */}
          <div className="space-y-2">
            <h3 className="font-semibold">Superficie (m²)</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="relative">
                <input
                  type="number"
                  placeholder="Mínima"
                  className="w-full p-2 pr-8 border rounded"
                  value={filtros.superficieMin}
                  onChange={(e) => setFiltros({ ...filtros, superficieMin: e.target.value })}
                />
                {filtros.superficieMin && (
                  <button
                    onClick={() => limpiarFiltro('superficieMin')}
                    className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    <X size={16} />
                  </button>
                )}
              </div>
              <div className="relative">
                <input
                  type="number"
                  placeholder="Máxima"
                  className="w-full p-2 pr-8 border rounded"
                  value={filtros.superficieMax}
                  onChange={(e) => setFiltros({ ...filtros, superficieMax: e.target.value })}
                />
                {filtros.superficieMax && (
                  <button
                    onClick={() => limpiarFiltro('superficieMax')}
                    className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    <X size={16} />
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Ubicación y Tipo en la misma fila */}
          <div className="grid grid-cols-2 gap-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Ubicación"
                className="w-full p-2 pr-8 border rounded"
                value={filtros.ubicacion}
                onChange={(e) => setFiltros({ ...filtros, ubicacion: e.target.value })}
              />
              {filtros.ubicacion && (
                <button
                  onClick={() => limpiarFiltro('ubicacion')}
                  className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <X size={16} />
                </button>
              )}
            </div>
            <div className="relative">
              <select
                className="w-full p-2 pr-8 border rounded appearance-none bg-white"
                value={filtros.tipo}
                onChange={(e) => setFiltros({ ...filtros, tipo: e.target.value })}
              >
                <option value="">Todos los tipos</option>
                <option value="Terreno">Terreno</option>
                <option value="Casa">Casa</option>
                <option value="Departamento">Departamento</option>
                <option value="Parcela">Parcela</option>
                <option value="Comercial">Comercial</option>
              </select>
              {filtros.tipo && (
                <button
                  onClick={() => limpiarFiltro('tipo')}
                  className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <X size={16} />
                </button>
              )}
            </div>
          </div>

          {/* Servicios */}
          <div className="space-y-2">
            <h3 className="font-semibold">Servicios</h3>
            <div className="grid grid-cols-3 gap-2">
              {['Agua', 'Luz', 'Gas', 'Internet', 'Alcantarillado', 'Pavimentación', 'Locomoción en puerta'].map(servicio => (
                <label key={servicio} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={filtros.servicios?.includes(servicio)}
                    onChange={(e) => {
                      const serviciosActualizados = e.target.checked
                        ? [...(filtros.servicios || []), servicio]
                        : (filtros.servicios || []).filter(s => s !== servicio);
                      setFiltros({ ...filtros, servicios: serviciosActualizados });
                    }}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm">{servicio}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filtros;