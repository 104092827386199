// src/components/pages/Contacto.js
import React from 'react';
import { Phone, Mail, MapPin, Facebook, Instagram, MessageCircle } from 'lucide-react';

const Contacto = () => {
  const [formStatus, setFormStatus] = React.useState({
    loading: false,
    error: null,
    success: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus({ loading: true, error: null, success: false });

    // Crear objeto con los datos del formulario
    const formData = {
      nombre: e.target.nombre.value,
      email: e.target.email.value,
      telefono: e.target.telefono.value,
      asunto: e.target.asunto.value,
      mensaje: e.target.mensaje.value
    };

    try {
      const response = await fetch('https://ventaterreno.cl/contact-handler.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Error al enviar el mensaje');
      }

      setFormStatus({
        loading: false,
        error: null,
        success: true
      });
      
      // Limpiar el formulario
      e.target.reset();
      
      // Opcional: Resetear el mensaje de éxito después de 5 segundos
      setTimeout(() => {
        setFormStatus(prev => ({ ...prev, success: false }));
      }, 5000);

    } catch (error) {
      setFormStatus({
        loading: false,
        error: error.message,
        success: false
      });
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      {/* Banner Principal */}
      <div className="bg-blue-600 text-white rounded-lg p-8 mb-12 text-center">
        <h1 className="text-3xl font-bold mb-4">¡Publica GRATIS tu propiedad!</h1>
        <p className="text-xl mb-6">
          Sin comisiones ni costos ocultos - Oferta por inauguración
        </p>
        <div className="bg-white text-blue-600 rounded-lg p-6 max-w-2xl mx-auto">
          <h2 className="text-2xl font-semibold mb-4">Publicamos:</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 text-lg">
            <div className="flex items-center justify-center p-2 bg-blue-50 rounded">
              ✓ Terrenos
            </div>
            <div className="flex items-center justify-center p-2 bg-blue-50 rounded">
              ✓ Casas
            </div>
            <div className="flex items-center justify-center p-2 bg-blue-50 rounded">
              ✓ Departamentos
            </div>
            <div className="flex items-center justify-center p-2 bg-blue-50 rounded">
              ✓ Parcelas
            </div>
            <div className="flex items-center justify-center p-2 bg-blue-50 rounded">
              ✓ Propiedades
            </div>
            <div className="flex items-center justify-center p-2 bg-blue-50 rounded">
              ✓ Sitios
            </div>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-12">
        {/* Información de Contacto */}
        <div className="space-y-8">
          <h2 className="text-2xl font-bold mb-6">Contáctanos</h2>
          
          {/* WhatsApp */}
          <a 
            href="https://wa.me/56938995544" 
            target="_blank" 
            rel="noopener noreferrer"
            className="flex items-center p-4 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
          >
            <MessageCircle size={24} className="mr-4" />
            <div>
              <div className="font-semibold">WhatsApp</div>
              <div>+56 9 3899 5544</div>
            </div>
          </a>

          {/* Teléfono */}
          <a 
            href="tel:+56938995544"
            className="flex items-center p-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            <Phone size={24} className="mr-4" />
            <div>
              <div className="font-semibold">Teléfono</div>
              <div>+56 9 3899 5544</div>
            </div>
          </a>

          {/* Correo */}
          <a 
            href="mailto:contacto@ventaterreno.cl"
            className="flex items-center p-4 bg-gray-700 text-white rounded-lg hover:bg-gray-800 transition-colors"
          >
            <Mail size={24} className="mr-4" />
            <div>
              <div className="font-semibold">Correo</div>
              <div>contacto@ventaterreno.cl</div>
            </div>
          </a>

          {/* Ubicación */}
          <div className="flex items-center p-4 bg-gray-100 rounded-lg">
            <MapPin size={24} className="mr-4 text-gray-700" />
            <div>
              <div className="font-semibold">Ubicación</div>
              <div>Santiago, Chile</div>
            </div>
          </div>

          {/* Redes Sociales */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Síguenos en redes sociales</h3>
            <div className="flex space-x-4">
              <a 
                href="https://facebook.com/ventaterreno.cl" 
                target="_blank" 
                rel="noopener noreferrer"
                className="p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
                aria-label="Facebook"
              >
                <Facebook size={24} />
              </a>
              <a 
                href="https://instagram.com/ventaterreno.cl" 
                target="_blank" 
                rel="noopener noreferrer"
                className="p-3 bg-gradient-to-r from-purple-500 via-pink-500 to-orange-500 text-white rounded-full hover:opacity-90 transition-opacity"
                aria-label="Instagram"
              >
                <Instagram size={24} />
              </a>
            </div>
          </div>
        </div>

        {/* Formulario de Contacto */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-6">Envíanos un mensaje</h2>
    <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="nombre" className="block text-sm font-medium text-gray-700 mb-1">
                Nombre
              </label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Correo electrónico
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>

            <div>
              <label htmlFor="telefono" className="block text-sm font-medium text-gray-700 mb-1">
                Teléfono
              </label>
              <input
                type="tel"
                id="telefono"
                name="telefono"
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label htmlFor="asunto" className="block text-sm font-medium text-gray-700 mb-1">
                Asunto
              </label>
              <select
                id="asunto"
                name="asunto"
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Selecciona una opción</option>
                <option value="publicar">Quiero publicar una propiedad</option>
                <option value="consulta">Consulta sobre una propiedad</option>
                <option value="otro">Otro</option>
              </select>
            </div>

            <div>
              <label htmlFor="mensaje" className="block text-sm font-medium text-gray-700 mb-1">
                Mensaje
              </label>
              <textarea
                id="mensaje"
                name="mensaje"
                rows="4"
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              ></textarea>
            </div>
      {formStatus.error && (
        <div className="p-3 bg-red-100 text-red-700 rounded-md">
          {formStatus.error}
        </div>
      )}

      {formStatus.success && (
        <div className="p-3 bg-green-100 text-green-700 rounded-md">
          ¡Mensaje enviado correctamente! Nos pondremos en contacto contigo pronto.
        </div>
      )}

      <button
        type="submit"
        disabled={formStatus.loading}
        className={`w-full bg-blue-600 text-white py-3 px-6 rounded-lg transition-colors font-semibold
          ${formStatus.loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
      >
        {formStatus.loading ? 'Enviando...' : 'Enviar mensaje'}
      </button>
    </form>
        </div>
      </div>

      {/* Información Adicional */}
      <div className="mt-12 bg-gray-50 p-8 rounded-lg">
        <h2 className="text-2xl font-bold mb-4">¿Por qué publicar con nosotros?</h2>
        <div className="grid md:grid-cols-3 gap-6">
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2">Sin Comisiones</h3>
            <p>Por tiempo limitado, publica tu propiedad sin pagar comisiones ni costos adicionales.</p>
          </div>
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2">Alcance Nacional</h3>
            <p>Tu propiedad será vista por compradores potenciales de todo Chile.</p>
          </div>
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2">Soporte Personalizado</h3>
            <p>Te ayudamos en todo el proceso de publicación.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
