// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import TerrenosList from './components/terrenos/TerrenosList';
import TerrenoDetalle from './components/terrenos/TerrenoDetalle';
import Contacto from './components/pages/Contacto'; // 👈 Importamos el componente
import { terrenosData } from './data/terrenosData';
import 'leaflet/dist/leaflet.css'; //mapa gratis
import AuthComponent from './components/pages/AuthComponent'; //login


function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route 
            path="/" 
            element={<TerrenosList terrenos={terrenosData} />} 
          />
          <Route 
            path="/terrenos" 
            element={<TerrenosList terrenos={terrenosData} />} 
          />
          <Route path="/auth" element={<AuthComponent />} />
          <Route 
            path="/terreno/:id" 
            element={<TerrenoDetalle terrenos={terrenosData} />} 
          />
          <Route 
            path="/contacto" 
            element={<Contacto />} 
          />
          <Route 
            path="*" 
            element={
              <div className="text-center py-20">
                <h2 className="text-2xl font-bold text-gray-800">Página no encontrada</h2>
                <p className="text-gray-600 mt-2">La página que buscas no existe.</p>
              </div>
            } 
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
