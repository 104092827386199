// src/components/terrenos/TerrenoDetalle.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ImageGallery from '../shared/ImageGallery';
import MapView from '../shared/MapView';
import MapLink from '../shared/MapLink';
import ContactModal from '../shared/ContactModal';
import { MapPin } from 'lucide-react';

const TerrenoDetalle = ({ terrenos }) => {
  const { id } = useParams();
  const terreno = terrenos.find(t => t.id === id);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  if (!terreno) {
    return <div className="text-center py-8">Terreno no encontrado</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="p-6">
            <ImageGallery images={terreno.imagenes} />
          </div>
          <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">{terreno.titulo}</h1>
            <div className="flex items-center text-gray-600 mb-4">
              <MapPin size={20} className="mr-2" />
              {terreno.ubicacion}
            </div>
            <p className="text-3xl font-bold text-gray-800 mb-6">
              ${terreno.precio.toLocaleString()}
            </p>
            
            <div className="space-y-4">
              <div className="flex items-center justify-between border-b pb-2">
                <span className="text-gray-600">Superficie</span>
                <span className="font-semibold">{terreno.superficie} m²</span>
              </div>
              <div className="flex items-center justify-between border-b pb-2">
                <span className="text-gray-600">Tipo</span>
                <span className="font-semibold">{terreno.tipo}</span>
              </div>
              <div className="flex items-center justify-between border-b pb-2">
                <span className="text-gray-600">Servicios</span>
                <span className="font-semibold">{terreno.servicios.join(', ')}</span>
              </div>
            </div>

            <div className="mt-8">
              <h2 className="text-xl font-bold mb-4">Descripción</h2>
              <p className="text-gray-600">{terreno.descripcion}</p>
            </div>

            <div className="mt-8">
              <button 
                onClick={() => setIsContactModalOpen(true)}
                className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
              >
                Contactar al vendedor
              </button>
            </div>
          </div>
        </div>

        {/* Sección del Mapa */}
        <div className="p-6 border-t">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Ubicación</h2>
            <MapLink 
              coordenadas={terreno.coordenadas} 
              direccion={terreno.ubicacion} 
            />
          </div>
          <MapView coordenadas={terreno.coordenadas} titulo={terreno.titulo} />
          <p className="mt-4 text-gray-600">
            {terreno.ubicacion}
          </p>
        </div>
      </div>

      {/* Modal de Contacto */}
      <ContactModal 
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
        datosContacto={terreno.datosContacto}
      />
    </div>
  );
};

export default TerrenoDetalle;
