// src/components/terrenos/TerrenosList.js
import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import Filtros from '../shared/Filtros';
import TerrenoCard from './TerrenoCard';
import DestacadosCarousel from '../shared/DestacadosCarousel'; // 👈 Importar el carrusel

const TerrenosList = ({ terrenos }) => {
  const [filtros, setFiltros] = useState(() => {
    const savedFilters = localStorage.getItem('filtrosActivos');
    return savedFilters ? JSON.parse(savedFilters) : {
      precioMin: '',
      precioMax: '',
      ubicacion: '',
      tipo: '',
      superficieMin: '',
      superficieMax: '',
      servicios: [],
      mostrarSoloDestacados: false
    };
  });

  // Filtrar terrenos
  const terrenosFiltrados = terrenos
    .filter(terreno => {
      if (filtros.mostrarSoloDestacados && !terreno.destacado) {
        return false;
      }
      return (
        (!filtros.precioMin || terreno.precio >= parseInt(filtros.precioMin)) &&
        (!filtros.precioMax || terreno.precio <= parseInt(filtros.precioMax)) &&
        (!filtros.ubicacion || terreno.ubicacion.toLowerCase().includes(filtros.ubicacion.toLowerCase())) &&
        (!filtros.tipo || terreno.tipo === filtros.tipo) &&
        (!filtros.superficieMin || terreno.superficie >= parseInt(filtros.superficieMin)) &&
        (!filtros.superficieMax || terreno.superficie <= parseInt(filtros.superficieMax)) &&
        (!filtros.servicios?.length || filtros.servicios.every(servicio => 
          terreno.servicios.includes(servicio)
        ))
      );
    })
    .sort((a, b) => {
      if (a.destacado && !b.destacado) return -1;
      if (!a.destacado && b.destacado) return 1;
      if (a.destacado && b.destacado) {
        if (a.fechaDestacado && b.fechaDestacado) {
          return new Date(b.fechaDestacado) - new Date(a.fechaDestacado);
        }
      }
      return a.precio - b.precio;
    });

  // Separar solo para el carrusel
  const terrenosDestacados = terrenosFiltrados.filter(t => t.destacado);
//  const terrenosRegulares = terrenosFiltrados.filter(t => !t.destacado);

  useEffect(() => {
    localStorage.setItem('filtrosActivos', JSON.stringify(filtros));
  }, [filtros]);

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <Filtros 
        filtros={filtros} 
        setFiltros={setFiltros}
        resultCount={terrenosFiltrados.length}
        totalCount={terrenos.length}
      />
      
      {/* Sección de destacados con carrusel */}
      {!filtros.mostrarSoloDestacados && terrenosDestacados.length > 0 && (
        <div className="mt-8 mb-12">
          <h2 className="text-2xl font-bold mb-6 flex items-center">
            <Star className="text-yellow-400 mr-2" size={24} fill="currentColor" />
            Propiedades Destacadas
          </h2>
          <DestacadosCarousel terrenos={terrenosDestacados} />
        </div>
      )}

      {/* Todas las propiedades (incluyendo destacadas) */}
      <div>
        <h2 className="text-2xl font-bold mb-6">Todas las Propiedades</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {terrenosFiltrados.map((terreno) => (
            <TerrenoCard key={terreno.id} terreno={terreno} />
          ))}
        </div>
      </div>

      {terrenosFiltrados.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-500 text-lg mb-4">
            No se encontraron propiedades con los filtros seleccionados
          </p>
          <button
            onClick={() => setFiltros({
              ...filtros,
              mostrarSoloDestacados: false
            })}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Mostrar todas las propiedades
          </button>
        </div>
      )}

      {terrenosFiltrados.length > 0 && (
        <div className="mt-4 text-center text-gray-500">
          Mostrando {terrenosFiltrados.length} de {terrenos.length} propiedades
        </div>
      )}
    </div>
  );
};

export default TerrenosList;
