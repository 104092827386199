// src/components/shared/MapView.js
import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Arreglo para el ícono del marcador
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MapView = ({ coordenadas, titulo }) => {
  // Efecto para controlar el z-index del mapa
  useEffect(() => {
    const adjustMapZIndex = () => {
      const mapContainer = document.querySelector('.leaflet-container');
      const mapPanes = document.querySelectorAll('.leaflet-pane');
      const mapControls = document.querySelectorAll('.leaflet-control');
      const mapOverlays = document.querySelectorAll('.leaflet-overlay-pane');
      
      if (mapContainer) {
        mapContainer.style.zIndex = '10';
      }
      
      mapPanes.forEach(pane => {
        pane.style.zIndex = '10';
      });
      
      mapControls.forEach(control => {
        control.style.zIndex = '10';
      });
      
      mapOverlays.forEach(overlay => {
        overlay.style.zIndex = '10';
      });
    };

    // Ajustar z-index inicial
    adjustMapZIndex();

    // Observer para manejar cambios dinámicos en el DOM
    const observer = new MutationObserver(adjustMapZIndex);
    const config = { childList: true, subtree: true };
    observer.observe(document.body, config);

    // Cleanup
    return () => observer.disconnect();
  }, []);

  if (!coordenadas || !coordenadas.lat || !coordenadas.lng) {
    return null;
  }

  const position = [coordenadas.lat, coordenadas.lng];

  return (
    <div className="h-[400px] w-full rounded-lg overflow-hidden shadow-lg relative" style={{ zIndex: 10 }}>
      <MapContainer 
        center={position} 
        zoom={15} 
        style={{ height: '100%', width: '100%' }}
        zIndex={10}
        className="relative z-10"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          zIndex={10}
        />
        <Marker position={position}>
          <Popup>
            <div className="font-semibold">{titulo}</div>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default MapView;
